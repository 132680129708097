import React from "react";
import Layout from "../components/Layout";
import Posts from "../components/Posts";
import { graphql } from "gatsby";
import SeO from "../components/SEO";

function posts({ data }) {
  const posts = data.allMdx.nodes;
  const total = data.allMdx.totalCount;
  console.log(total);
  return (
    <Layout>
      <SeO title="Posts" />
      <section className="pageContainer">
        <Posts posts={posts} title={`${total}  posts`} />
      </section>
    </Layout>
  );
}

export default posts;

export const query = graphql`
  {
    allMdx(sort: { fields: frontmatter___Update, order: DESC }) {
      nodes {
        id
        frontmatter {
          title
          category
          readTime
          slug
          Update(formatString: "MMM,Do,YYYY")
        }
        excerpt
      }
      totalCount
    }
  }
`;
